import Vue from 'vue'

Vue.filter('issueCategory', function (value) {
  if (!value) return ''

  switch (value) {
    case 'IN':
      return 'eingehende Nachricht'
    case 'OUT':
      return 'ausgehende Nachricht'
    case 'FFF':
      return 'Systemnachricht'
    case 'FMO':
      return 'Systemnachricht'
    default:
      return value
  }
})

Vue.filter('issueStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'INFO':
      return 'Information'
    case 'OPEN':
      return 'Offen, warten auf Antwort'
    case 'ANSWER':
      return 'Offen, Antwort erhalten'
    case 'CLOSED':
      return 'Geschlossen'
    default:
      return value
  }
})

Vue.filter('issueHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'ISSUE_CLOSED':
      return 'Vorgang geschlossen'
    case 'ISSUE_OPEN':
      return 'Vorgang wieder geöffnet'
    case 'ISSUE_RECEIVED_EMAIL':
      return 'Nachricht eingegangen'
    case 'NEW_ISSUE_RECEIVED_EMAIL':
      return 'Neue Nachricht eingegangen'
    case 'ISSUE_CONTACT_EMAIL':
      return 'Nachricht versendet'

    default:
      return value
  }
})
