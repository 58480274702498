import Vue from 'vue'

Vue.filter('tradeInProcessStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'IN_PROGRESS':
      return 'In Arbeit'
    case 'OK':
      return 'Abgeschlossen'
    case 'CANCELED':
      return 'Abgebrochen'
    default:
      return value
  }
})
