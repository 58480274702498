<template>
  <v-alert v-bind="$attrs" type="warning" text dense class="mb-0">
    Der Filter ist aktiviert. Es wird nur eine eingeschränkte Liste angezeigt.
  </v-alert>
</template>

<script>
export default {
  name: 'AlertFilterActive'
}
</script>
