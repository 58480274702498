import Vue from 'vue'
import store from '@/store'

Vue.filter('number', function (value, fractionDigits, returnNullIfEmpty) {
  if (returnNullIfEmpty && (value === null || value === undefined)) {
    return null
  } else {
    const zen = store.getters['theme/zen']
    return (value || 0).toLocaleString('de-DE', {
      minimumFractionDigits: zen
        ? 0
        : fractionDigits
        ? fractionDigits
        : undefined,
      maximumFractionDigits: zen
        ? 0
        : fractionDigits
        ? fractionDigits
        : undefined
    })
  }
})

Vue.filter('numberWithSign', function (value, fractionDigits) {
  const filter = Vue.filter('number')
  const string = filter(Math.abs(value), fractionDigits) // always positive number
  const sign = value === 0 ? '' : value > 0 ? '+' : '-'

  return `${sign} ${string}`
})
