import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,

  state: {
    // we will save the configuration for the different pages here.
    //! The property name should be the name of the route.

    /**
     * Fahrzeugmarkt
     */
    'customers-overview': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    'fahrzeugmarkt-rangliste': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['leads'],
      sortDesc: [true]
    },

    'fahrzeugmarkt-valuations': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true]
    },

    'fahrzeugmarkt-trade-in-processes': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true]
    },

    'fahrzeugmarkt-fahrzeuge': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['updated_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    'fahrzeugmarkt-fahrzeugangebote': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['updated_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    leads: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    'storages-overview': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    'invoices-overview': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    'fahrzeugmarkt-emailings': {
      page: 1,
      itemsPerPage: 10
    },

    'fahrzeugmarkt-contracts-sell': {
      quickfilter: {
        selected: [],
        search: null
      }
    },

    'fahrzeugmarkt-contracts-buy': {
      quickfilter: {
        selected: [],
        search: null
      }
    },

    'fahrzeugmarkt-contracts-order': {
      quickfilter: {
        selected: [],
        search: null
      }
    },

    lead: {
      layout: {
        actionhistory: 'TIMELINE',
        actionhistoryorder: 'DESC'
      }
    },

    'workshop-workshoprepairreports': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true]
    },

    /**
     * Bestand
     */
    'bestand-partners': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },
    'bestand-vertraege': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },
    'bestand-schaeden': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    /**
     * Applications
     */
    'freshcarflat-applications': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['updated_at'],
      sortDesc: [true],
      quickfilter: {
        search: null
      }
    },
    'freshcarsharing-applications': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['updated_at'],
      sortDesc: [true],
      quickfilter: {
        search: null,
        groupBy: null
      }
    },
    'freshbike-bikes': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['updated_at'],
      sortDesc: [true],
      quickfilter: {
        search: null,
        groupBy: null,
        selected: []
      }
    },

    /**
     * Schadenmeldung
     */
    damages: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    /**
     * Sicherungsscheine
     */
    'lessor-certificates': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true],
      quickfilter: {
        selected: [],
        search: null
      }
    },

    /**
     * Tools
     */
    'tools-bg-removal-process': {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [true]
    }
  },

  mutations: { updateField },

  actions: {},

  getters: { getField }
}
