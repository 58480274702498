import gql from 'graphql-tag'
import { AUDIT } from '@freshsolutions/fresh-vue-audits/src/graphql/fragments.js'
import { FILE } from '@freshsolutions/fresh-vue-files/src/graphql/fragments.js'

// Bestand
export const BESTANDPARTNER = gql`
  fragment Bestandpartner on BestandPartner {
    id
    salutation {
      id
      code
      name
    }
    firstname
    lastname
    company
    birthdate
    land {
      id
      code
      name
    }
    external_delivery

    insuranceCompanies {
      id
      zusatz
      pivot {
        partner_number
        note
      }
    }

    bestandAdressen {
      id
      street
      zip
      city
    }

    bestandKommunikationen {
      id
      type {
        id
        code
        name
      }
      data
      comment
    }

    bestandBankkonten {
      id
      bank
      iban
      bic
      comment
    }

    bestandVertraege {
      id
      insuranceCompany {
        id
        zusatz
      }
      contract_number
      version_number
      product_number
      product_text
      contract_start
      contract_end
    }

    bestandSchaeden {
      id
      bestandVertrag {
        contract_number
        id
        insuranceCompany {
          id
          zusatz
        }
      }
      damage_number
      version_number
      reporting_date
      event_date
    }

    created_at
    updated_at
  }
`

export const BESTANDPARTNER_SIMPLE = gql`
  fragment BestandpartnerSimple on BestandPartner {
    id
    salutation {
      id
      code
      name
    }
    firstname
    lastname
    company
    birthdate
    land {
      id
      code
      name
    }
    external_delivery

    bestandAdressen {
      id
      street
      zip
      city
    }

    created_at
    updated_at
  }
`

export const BESTANDVERTRAG = gql`
  fragment Bestandvertrag on BestandVertrag {
    id
    bestandPartner {
      ...BestandpartnerSimple
    }
    insuranceCompany {
      id
      zusatz
    }
    contract_number
    version_number
    product_number
    product_text
    contract_start
    contract_end
    main_maturity
    debitType {
      id
      code
      name
    }
    paymentMethod {
      id
      code
      name
    }
    price_gross
    price_net
    cover_note
    avm
    bvm

    bestandSchaeden {
      id
      bestandVertrag {
        id
        insuranceCompany {
          id
          zusatz
        }
      }
      damage_number
      version_number
      reporting_date
      event_date
    }

    created_at
    updated_at
  }
  ${BESTANDPARTNER_SIMPLE}
`

export const BESTANDVERTRAG_SIMPLE = gql`
  fragment BestandvertragSimple on BestandVertrag {
    id
    bestandPartner {
      ...BestandpartnerSimple
    }
    insuranceCompany {
      id
      zusatz
    }
    contract_number
    version_number
    product_number
    product_text
    contract_start
    contract_end

    created_at
    updated_at
  }
  ${BESTANDPARTNER_SIMPLE}
`

export const BESTANDSCHADEN = gql`
  fragment Bestandschaden on BestandSchaden {
    id
    bestandVertrag {
      ...BestandvertragSimple

      bestandPartner {
        ...BestandpartnerSimple
      }
    }
    damage_number
    version_number
    reporting_date
    event_date
    description
    damage_note
    status
    payment
    payment_reserve
    payment_deductible
    payment_deductible_paid
    payment_total
    payment_total_actual

    bestandSchadenZahlungen {
      id
      payment_number
      role_code
      salutation {
        id
        code
        name
      }
      name
      street
      zip
      city
      bank
      iban
      bic
      payment_date
      amount
    }

    created_at
    updated_at
  }
  ${BESTANDPARTNER_SIMPLE}
  ${BESTANDVERTRAG_SIMPLE}
`

export const BESTANDSCHADEN_SIMPLE = gql`
  fragment BestandschadenSimple on BestandSchaden {
    id
    bestandVertrag {
      ...BestandvertragSimple
    }
    damage_number
    version_number
    reporting_date
    event_date

    payment_total

    created_at
    updated_at
  }
  ${BESTANDVERTRAG_SIMPLE}
`

// Tabellenwert
export const TABELLENWERT = gql`
  fragment Tabellenwert on Tabellenwert {
    code
    value
  }
`

// Settings
export const SETTING = gql`
  fragment Setting on Setting {
    id
    settingable_type
    settingable_id
    key
    value

    files {
      ...File
      url_show
      url_download
    }
  }
  ${FILE}
`

// User
export const USER = gql`
  fragment User on User {
    id
    name
    email
    last_login_at
    disabled
    created_at
    updated_at
    roles
    permissions
  }
`

export const CONTROLLING_USER = gql`
  fragment ControllingUser on User {
    id
    email
    email_verified_at
    last_login_at
    disabled

    partner {
      id
    }

    created_at
  }
`

export const ADMIN_USER = gql`
  fragment AdminUser on User {
    id
    email
    email_verified_at
    last_login_at
    disabled

    partner {
      id
    }

    employee {
      name
    }

    roles

    created_at
    updated_at
  }
`

export const PERMISSION_USER = gql`
  fragment PermissionUser on User {
    id
    email
    last_login_at
    disabled
    created_at

    roles
    permissions
  }
`

// Partner
export const ADRESSE = gql`
  fragment Adresse on Adresse {
    id
    typ {
      ...Tabellenwert
    }
    strasse
    plz
    ort
  }
  ${TABELLENWERT}
`

export const KOMMUNIKATIONSMEDIUM = gql`
  fragment Kommunikationsmedium on Kommunikationsmedium {
    id
    typ {
      ...Tabellenwert
    }
    value
  }
  ${TABELLENWERT}
`

export const PARTNERINFO = gql`
  fragment Partnerinfo on Partnerinfo {
    id
    infos
  }
`

export const PARTNER_SIMPLE = gql`
  fragment PartnerSimple on Partner {
    id
    name
    vorname
    nachname
    zusatz
  }
`

export const PARTNER = gql`
  fragment Partner on Partner {
    id
    name
    vorname
    nachname
    zusatz
    adressen {
      ...Adresse
    }
    kommunikationsmedien {
      ...Kommunikationsmedium
    }
    user {
      ...User
    }
    reports {
      id
      name
      description
    }
  }
  ${USER}
  ${ADRESSE}
  ${KOMMUNIKATIONSMEDIUM}
`

export const PARTNERTAG = gql`
  fragment Partnertag on Partnertag {
    id
    name
    type
  }
`

// extends Partner
export const EMPLOYEE = gql`
  fragment Employee on Employee {
    id
    name
    vorname
    nachname
    adressen {
      ...Adresse
    }
    kommunikationsmedien {
      ...Kommunikationsmedium
    }
    user {
      ...User
    }
    reports {
      id
      name
      description
    }
  }
  ${USER}
  ${ADRESSE}
  ${KOMMUNIKATIONSMEDIUM}
`
// extends Partner
export const EMPLOYER_SIMPLE = gql`
  fragment EmployerSimple on Employer {
    id
    zusatz
    name
  }
`

// extends Partner
export const VERKAEUFER = gql`
  fragment Verkaeufer on Verkaeufer {
    id
    name
    vorname
    nachname
    adressen {
      ...Adresse
    }
    kommunikationsmedien {
      ...Kommunikationsmedium
    }
    user {
      ...User
    }
  }
  ${USER}
  ${ADRESSE}
  ${KOMMUNIKATIONSMEDIUM}
`

// extends Partner
export const AUTOHAUS = gql`
  fragment AutohausStandort on Autohaus {
    id
    zusatz
    name
    isActiveFreshcarflatAutohaus
    isActiveFahrzeugmarktAutohaus
    verkaeufer(first: 999, page: 1) {
      data {
        ...Verkaeufer
        user {
          ...User
          partner {
            ...PartnerSimple
          }
        }
      }
    }
    adressen {
      ...Adresse
    }
    hauptadressen {
      ...Adresse
    }
  }

  fragment Autohaus on Autohaus {
    ...AutohausStandort
    standorte {
      ...AutohausStandort
    }
  }
  ${VERKAEUFER}
  ${USER}
  ${PARTNER_SIMPLE}
  ${ADRESSE}
`

// extends Partner
export const AUTOHAUS_SIMPLE = gql`
  fragment AutohausSimple on Autohaus {
    id
    zusatz
    name
    hauptadressen {
      ...Adresse
    }
  }
  ${ADRESSE}
`

// Meeting
export const MEETING_SIMPLE = gql`
  fragment MeetingSimple on Meeting {
    id
    user {
      id
      name
    }

    meetingType {
      id
      code
      name
    }

    event {
      id
      start
      end
    }

    location
    comment
  }
`

// Mail
export const MAIL_SIMPLE = gql`
  fragment MailSimple on Mail {
    id
    attachments {
      id
      file {
        ...File

        filename
      }
    }

    date
    recipient
    sender
    # body
    data

    created_at
    updated_at
  }
  ${FILE}
`

// Maillogs
export const MAILLOG_SIMPLE = gql`
  fragment MaillogSimple on Maillog {
    id

    attachments {
      id
      cid
      disposition

      file {
        ...File
        filename
      }
    }

    events {
      id
      event
      timestamp
      eventdata
    }

    headers
    # body
    data

    created_at
  }
  ${FILE}
`

export const MAILLOG_SIMPLE_VIEW_DATA = gql`
  fragment MaillogSimpleViewData on Maillog {
    id
    # we need to order them to get the newest event first (for opened timestamp)
    events {
      id
      event
      timestamp
    }
    headers
    body
    created_at
  }
`

// E-Mailing
export const EMAILINGRECIPIENT_SIMPLE = gql`
  fragment EmailingrecipientSimple on Emailingrecipient {
    id
    email
  }
`

export const EMAILING_SIMPLE = gql`
  fragment EmailingSimple on Emailing {
    id
    name
  }
`

// Fahrzeugmarkt Customers
export const CUSTOMER = gql`
  fragment Customer on Customer {
    id
    autohaus {
      ...AutohausSimple
    }
    bearbeiter {
      id
      name
      email
      absence_status
      partner {
        id
      }
    }
    status {
      id
      code
      name
      sort
    }
    identifier
    internal_identifier
    internal_fibu_number
    salutation {
      id
      code
      category
      name
      sort
    }
    name
    firstname
    lastname
    company
    branch
    driver_license_number
    driver_license_location
    driver_license_date
    birthdate
    birthdayNotificationEnabled
    birthdayNotificationOffset
    placeofbirth
    comment
    infos
    dsgvo

    addresses {
      id
      street
      zip
      city
      comment
      dsgvo
    }

    communications {
      id
      type {
        id
        code
        name
      }
      data
      comment
      dsgvo
    }

    bankdetails {
      id
      bank
      iban
      bic
      comment
    }

    contactpersons {
      id
      type
      salutation {
        id
        code
        category
        name
        sort
      }
      firstname
      lastname
      birthdate
      street
      zip
      city
      emailp
      emailg
      telp
      telg
      telm
      telmg
      faxp
      faxg
      comment
      dsgvo
    }

    leads {
      id
      freitext_marke
      interne_nummer
      email
      autohaus {
        id
        zusatz
        name
        hauptadressen {
          ...Adresse
        }
      }
      bearbeiter {
        id
        name
      }
      leadquelle {
        id
        quelle
      }
      created_at
      updated_at
      status
      bearbeitungsstatus
      closed_reason
    }

    created_at
    updated_at
    createdByName
    created_by {
      id
      name
    }
    updatedByName
    updated_by {
      id
      name
    }
  }
  ${AUTOHAUS_SIMPLE}
  ${ADRESSE}
`

export const CUSTOMER_SIMPLE = gql`
  fragment CustomerSimple on Customer {
    id
    autohaus {
      ...AutohausSimple
    }
    bearbeiter {
      id
      name
    }
    status {
      id
      code
      name
      sort
    }
    identifier
    internal_identifier
    salutation {
      id
      code
      category
      name
      sort
    }
    name
    firstname
    lastname
    company
    branch
    driver_license_number
    driver_license_location
    driver_license_date
    birthdate
    placeofbirth
    comment
    infos
    dsgvo

    addresses {
      id
      street
      zip
      city
    }

    communications {
      id
      type {
        id
        code
      }
      data
      comment
      dsgvo
    }

    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${AUTOHAUS_SIMPLE}
`

// Fahrzeugmarkt
export const FAHRZEUGMARKE = gql`
  fragment Fahrzeugmarke on Fahrzeugmarke {
    id
    marke
  }
`

export const FAHRZEUGMODELL = gql`
  fragment Fahrzeugmodell on Fahrzeugmodell {
    id
    modell
  }
`

export const FAHRZEUGVERTRAGSART = gql`
  fragment Fahrzeugvertragsart on Fahrzeugvertragsart {
    id
    kategorie
    text
  }
`

export const FAHRZEUGANGEBOTPRICE = gql`
  fragment Fahrzeugangebotprice on Fahrzeugangebotprice {
    id
    fahrzeugvertragsart {
      ...Fahrzeugvertragsart
    }
    duration
    mileage
    price_gross
    price_net
  }
  ${FAHRZEUGVERTRAGSART}
`

export const FAHRZEUGANGEBOTCHANGEPRICE = gql`
  fragment Fahrzeugangebotchangeprice on Fahrzeugangebotchangeprice {
    id
    fahrzeugvertragsart {
      ...Fahrzeugvertragsart
    }
    valid_from
    changetype
    valuetype
    price
    percentage
    changed

    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${FAHRZEUGVERTRAGSART}
`

export const FAHRZEUGREIFEN = gql`
  fragment Fahrzeugreifen on Fahrzeugreifen {
    id
    text
    order
    valid_from
    valid_to
  }
`

export const FAHRZEUGDETAIL = gql`
  fragment Fahrzeugdetail on Fahrzeugdetail {
    id
    kategorie
    anzeigeart
    text
  }
`

export const FAHRZEUGDETAIL_PIVOT = gql`
  fragment FahrzeugdetailPivot on Fahrzeugdetail {
    id
    kategorie
    text
    pivot {
      wert
      zusatz_number
      zusatz_string
    }
  }
`

export const FAHRZEUGANGEBOTDETAIL = gql`
  fragment Fahrzeugangebotdetail on Fahrzeugangebotdetail {
    id
    kategorie
    anzeigeart
    text
  }
`

export const FAHRZEUGANGEBOTDETAIL_PIVOT = gql`
  fragment FahrzeugangebotdetailPivot on Fahrzeugangebotdetail {
    id
    kategorie
    anzeigeart
    text
    pivot {
      wert
      zusatz_number
      zusatz_string
    }
  }
`

export const FAHRZEUGPRAEMIE = gql`
  fragment Fahrzeugpraemie on Fahrzeugpraemie {
    id
    type
    category
    percent
    value
  }
`

export const FAHRZEUGPROFITITEM = gql`
  fragment FahrzeugprofitItem on FahrzeugprofitItem {
    id
    type
    description
    gross
    net
    tax
  }
`

export const FAHRZEUGPROFIT = gql`
  fragment Fahrzeugprofit on Fahrzeugprofit {
    id
    fahrzeug {
      id
      interne_nummer
      fahrzeugmarke {
        ...Fahrzeugmarke
      }
      fahrzeugmodell {
        ...Fahrzeugmodell
      }
      status {
        id
        code
        name
        sort
      }
      autohaus {
        id
        zusatz
      }
    }
    rechnungsbetrag_brutto
    rechnungsbetrag_netto
    rechnungsdatum
    purchase_bill_number
    purchase_tax_deductible
    einkauf_bearbeiter {
      id
      name
      email
      absence_status
      partner {
        id
        name
      }
    }
    fahrzeugpurchase {
      id
      internal_id
      purchase_date
      customer {
        id
        identifier
        shortDescription
      }
    }
    verkaufsdatum
    sale_bill_number
    sale_tax_deductible
    verkaufspreis_brutto
    verkaufspreis_netto
    verkauf_bearbeiter {
      id
      name
      email
      absence_status
      partner {
        id
        name
      }
    }
    fahrzeugangebotcontract {
      id
      internal_id
      contract_date
      customer {
        id
        identifier
        shortDescription
      }
    }
    steuerart
    profits_checked
    profitNetOnly
    fahrzeugprofitItems {
      ...FahrzeugprofitItem
    }
    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${FAHRZEUGMARKE}
  ${FAHRZEUGMODELL}
  ${FAHRZEUGPROFITITEM}
`

export const FAHRZEUGVORSCHADEN = gql`
  fragment Fahrzeugvorschaden on Fahrzeugvorschaden {
    id
    kategorie
    text
    repaired
    images {
      ...File
      url
    }
    files {
      ...File
      url
    }
  }
  ${FILE}
`

export const FAHRZEUGANGEBOTPORTAL_PIVOT = gql`
  fragment FahrzeugangebotportalPivot on Leadquelle {
    id
    quelle
    pivot {
      id
      valid_from
      valid_to
      status
      published
    }
  }
`

export const LEADQUELLEKOSTEN = gql`
  fragment Leadquellekosten on Leadquellekosten {
    id
    advertisement
    lead
    lead_success
  }
`

export const LEADQUELLE = gql`
  fragment Leadquelle on Leadquelle {
    id
    leadquellekosten {
      ...Leadquellekosten
    }

    quelle
    type
    order
    disabled
  }
  ${LEADQUELLEKOSTEN}
`

export const LEAD = gql`
  fragment Lead on Lead {
    id
    leadquelle {
      ...Leadquelle
    }
    leadType {
      id
      code
      name
    }
    emailing {
      ...EmailingSimple
    }
    mail {
      ...MailSimple
    }
    vertragsart {
      id
      kategorie
      text
    }
    autohaus {
      ...AutohausSimple
    }
    bearbeiter {
      id
      name
    }
    reminder {
      id
      start
      end
    }
    fahrzeugangebot {
      id
      interne_nummer
      titel
      vertragsarten {
        id
        kategorie
        text
      }
      ls_monat_brutto
      vk_preis_brutto
      fahrzeugangebotcontracts_closed_count
      angebotfahrzeugart {
        ...Tabellenwert
      }
      fahrzeug(trashed: WITH) {
        id
        autohaus {
          ...AutohausSimple
        }
        fahrzeugmarke {
          ...Fahrzeugmarke
        }
        fahrzeugmodell {
          ...Fahrzeugmodell
        }
        status {
          id
          code
          name
          sort
        }
        deleted_at
      }
      fahrzeugangebotportale(first: 999) {
        edges {
          node {
            ...Leadquelle
          }

          valid_from
          valid_to
          status
          published
        }
      }
      deleted_at
    }
    freitext_marke
    digital
    interne_nummer
    anrede {
      ...Tabellenwert
    }
    vorname
    nachname
    zusatz
    branche
    adresse
    plz
    ort
    telefon
    email
    driver_license_number
    driver_license_location
    driver_license_date
    nachricht
    infos
    status
    bearbeitungsstatus
    closed_reason
    sold_kfz
    reminder_reached
    last_expose_send
    last_expose_recipient
    spam
    spam_reason
    spam_score
    spam_action

    customer {
      ...CustomerSimple
    }

    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${CUSTOMER_SIMPLE}
  ${TABELLENWERT}
  ${EMAILING_SIMPLE}
  ${MAIL_SIMPLE}
  ${AUTOHAUS_SIMPLE}
  ${FAHRZEUGMARKE}
  ${FAHRZEUGMODELL}
  ${LEADQUELLE}
`

export const FAHRZEUGPURCHASE = gql`
  fragment Fahrzeugpurchase on Fahrzeugpurchase {
    id
    internal_id
    status {
      id
      code
      name
    }
    customer {
      id
      name
      communications {
        id
        type {
          id
          code
          name
        }
        data
        comment
        dsgvo
      }
    }
    user {
      id
      email
      partner {
        id
        name
      }
    }
    created_at
    updated_at
  }
`

export const FAHRZEUG = gql`
  fragment Fahrzeug on Fahrzeug {
    id
    autohaus {
      ...AutohausSimple
    }
    fahrzeugmarke {
      ...Fahrzeugmarke
    }
    fahrzeugmodell {
      ...Fahrzeugmodell
    }
    fahrzeugdetails {
      ...FahrzeugdetailPivot
    }
    fahrzeugvorschaeden {
      ...Fahrzeugvorschaden
    }
    fahrzeugprofits {
      ...Fahrzeugprofit
    }
    interne_nummer
    status {
      id
      code
      name
      sort
    }
    infos
    tracking
    internal_notes
    precontractual
    zusatz
    interne_identnr
    buchnummer
    fahrzeugidentnr
    kennzeichen
    treibstoff {
      ...Tabellenwert
    }
    ts_komb
    ts_io
    ts_ao
    ts_reichweite
    fuelSuffix
    hybrid_komb
    hybrid_reichweite
    wltp_slow
    wltp_medium
    wltp_fast
    wltp_veryfast
    wltp_combined
    costs_tax
    costs_fuel
    costs_fuel_date
    costs_energy_year
    costs_co2_price_low
    costs_co2_low
    costs_co2_price_middle
    costs_co2_middle
    costs_co2_price_high
    costs_co2_high
    tankfuellstand {
      ...Tabellenwert
    }
    fahrzeugart {
      ...Tabellenwert
    }
    erstzulassung
    letzte_zulassung
    abmeldedatum
    letzter_besuch
    baujahr
    kilometerstand
    kilometerstand_datum
    hu_au_neu
    hu_au_valid_to
    inspection_valid_to
    inspection_rotation
    inspection_rotation_km
    meeting_reminder_stop
    hu_au_last_reminder
    inspection_last_reminder
    scheckheftgepflegt
    vorbesitzer_fz
    vorbesitzer_vb
    vorbesitzernutzung {
      ...Tabellenwert
    }
    qualitaetssiegel {
      ...Tabellenwert
    }
    bereifung {
      ...Tabellenwert
    }
    fahrzeugreifen {
      ...Fahrzeugreifen
    }
    reifentyp
    reifengroesse
    reifenklasse {
      ...Tabellenwert
    }
    reifeneffizienzklasse {
      ...Tabellenwert
    }
    reifenhaftungsklasse {
      ...Tabellenwert
    }
    reifenrollgeraeusch
    reifengeraeuschklasse {
      ...Tabellenwert
    }
    reifenqrcode
    schadstoffklasse {
      ...Tabellenwert
    }
    feinstaubplakette {
      ...Tabellenwert
    }
    effizienzklasse {
      ...Tabellenwert
    }
    emission_slow
    emission_medium
    emission_fast
    emission_veryfast
    emission_combined
    verfuegbar {
      ...Tabellenwert
    }
    bereitstellung
    verkauf_ab
    lieferung_ab
    zusatz_verfuegbarkeit
    hsn
    tsn
    kba
    fahrzeugbriefnummer
    getriebe {
      ...Tabellenwert
    }
    antriebsart {
      ...Tabellenwert
    }
    motorisierung
    ps
    kw
    ccm
    fahrzeugtyp {
      ...Tabellenwert
    }
    tueren
    sitze
    aussenfarbe {
      ...Tabellenwert
    }
    aussenfarbe_zusatz
    interieurfarbe {
      ...Tabellenwert
    }
    interieurtyp {
      ...Tabellenwert
    }
    innenfarbe_zusatz
    beschreibung
    textequipment
    beschreibung_serienausstattung
    beschreibung_sonderausstattung
    haendlerpreis_brutto
    listenpreis_brutto
    bestand_seit
    holding_days
    herkunft
    garantie
    garantie_bis
    insurer
    evb_type
    evb_number
    position_lager
    schluessel_nummer
    schluessel_platz
    steuerung_al {
      ...AutohausSimple
    }
    steuerung_ws {
      ...AutohausSimple
    }
    steuerung_sa {
      ...AutohausSimple
    }

    fahrzeugangebote {
      id
      titel
      interne_nummer
      angebotfahrzeugart {
        ...Tabellenwert
      }
      fahrzeugangebotclicks_count
      leads_count
      vk_preis_brutto

      fahrzeugangebotportale(first: 999) {
        edges {
          node {
            ...Leadquelle
          }

          valid_from
          valid_to
          status
          published
        }
      }

      fahrzeugangebotcontracts_count
      fahrzeugangebotcontracts_closed_count
    }

    latestActiveFahrzeugreservation {
      id
      user {
        id
        name
      }
      event {
        id
        start
        end
      }
      customer {
        id
        identifier
        shortDescription
      }
      lead {
        id
        interne_nummer
        shortDescription
      }
      comment
      created_at
      updated_at
      created_by {
        id
        name
      }
    }

    fahrzeugreservations {
      id
      user {
        id
        name
      }
      event {
        id
        start
        end
        timed
      }
      customer {
        id
        identifier
        shortDescription
      }
      lead {
        id
        interne_nummer
        shortDescription
      }
      comment
      created_at
      updated_at
      created_by {
        id
        name
      }
    }

    created_at
    updated_at
    created_by {
      id
      name
    }
    createdByName
    updated_by {
      id
      name
    }
    updatedByName
  }
  ${TABELLENWERT}
  ${AUTOHAUS_SIMPLE}
  ${FAHRZEUGMARKE}
  ${FAHRZEUGMODELL}
  ${FAHRZEUGREIFEN}
  ${FAHRZEUGDETAIL_PIVOT}
  ${LEADQUELLE}
  ${FAHRZEUGVORSCHADEN}
  ${FAHRZEUGPROFIT}
`

export const FAHRZEUG_DETAILS_SHORT = gql`
  fragment FahrzeugDetailsShort on Fahrzeug {
    id
    fahrzeugmarke {
      ...Fahrzeugmarke
    }
    fahrzeugmodell {
      ...Fahrzeugmodell
    }
    fahrzeugtyp {
      ...Tabellenwert
    }
    fahrzeugart {
      ...Tabellenwert
    }
    interne_identnr
    buchnummer
    fahrzeugidentnr
    hsn
    tsn
  }
  ${TABELLENWERT}
  ${FAHRZEUGMARKE}
  ${FAHRZEUGMODELL}
`
export const FAHRZEUGANGEBOTCONTRACT = gql`
  fragment Fahrzeugangebotcontract on Fahrzeugangebotcontract {
    id
    internal_id
    type
    status {
      id
      code
      name
    }
    customer {
      id
      name
      communications {
        id
        type {
          id
          code
          name
        }
        data
        comment
        dsgvo
      }
    }
    user {
      id
      email
      partner {
        id
        name
      }
    }
    created_at
    updated_at
  }
`

export const FAHRZEUGANGEBOT = gql`
  fragment Fahrzeugangebot on Fahrzeugangebot {
    id
    fahrzeug {
      ...Fahrzeug
    }
    fahrzeugangebotdetails {
      ...FahrzeugangebotdetailPivot
    }
    fahrzeugpraemien {
      ...Fahrzeugpraemie
    }

    vertragsarten {
      ...Fahrzeugvertragsart
    }

    fahrzeugangebotprices {
      ...Fahrzeugangebotprice
    }

    fahrzeugangebotportale(first: 999) {
      edges {
        node {
          ...Leadquelle
        }

        valid_from
        valid_to
        status
        published
      }
    }

    interne_nummer
    anzahl_verfuegbar
    angebotfahrzeugart {
      ...Tabellenwert
    }
    zielgruppe {
      ...Tabellenwert
    }

    ls_anfrage
    ls_gesamtbetrag
    ls_nettodarlehensbetrag
    ls_sollzins
    ls_jahreszins
    ls_leasingbank
    ls_anzahlung
    ls_schlussrate
    ls_laufzeit_monate
    ls_fahrleistung_km
    ls_leasingfaktor
    ls_show_leasingfaktor
    ls_monat_brutto
    ls_monat_netto
    ls_restwert
    ls_bemerkung
    ls_km_verguetung
    ls_km_kosten

    fz_anfrage
    fz_finanzierungsbank
    fz_monat_brutto
    fz_monat_netto
    fz_sollzins
    fz_jahreszins
    fz_laufzeit_monate
    fz_fahrleistung_km
    fz_anzahlung
    fz_schlussrate
    fz_kreditbetrag_brutto
    fz_kreditbetrag_netto
    fz_abschlussgebuehren_brutto
    fz_ratenabsicherung_brutto
    fz_bemerkung

    vk_anfrage
    vk_preis_brutto
    vk_preis_netto
    vk_bemerkung

    ab_anfrage
    ab_mietpreis_brutto
    ab_mietpreis_netto
    ab_inklusiv_km
    ab_km_verguetung
    ab_km_kosten
    ab_laufzeit_monate
    ab_bemerkung

    titel
    internal_notes

    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${FAHRZEUG}
  ${FAHRZEUGPRAEMIE}
  ${TABELLENWERT}
  ${FAHRZEUGVERTRAGSART}
  ${FAHRZEUGANGEBOTPRICE}
  ${LEADQUELLE}
  ${FAHRZEUGANGEBOTDETAIL_PIVOT}
`

// Fahrzeugmarkt Werkstatt
export const STORAGE = gql`
  fragment Storage on Storage {
    id
    autohaus {
      ...AutohausSimple
    }
    storageType {
      id
      code
      name
    }
    storageStatus {
      id
      code
      name
    }
    customer {
      ...CustomerSimple
    }
    fahrzeug {
      ...Fahrzeug
    }
    bearbeiter {
      id
      name
      email
      absence_status
      partner {
        id
      }
    }
    fahrzeugmarke {
      id
      marke
    }
    fahrzeugmodell {
      id
      modell
    }
    kfz_kennzeichen
    fahrzeugidentnr
    identifier
    internal_identifier
    order_date
    storage_place
    comment

    tires {
      id
      note
      fahrzeugreifen {
        id
        text
      }
      tire_type {
        ...Tabellenwert
      }
      rims {
        ...Fahrzeugdetail
      }
      quantity
      profile_type
      tire_dimension
      dot_number
      tread_depth_inside
      tread_depth_center
      tread_depth_outside
      air_pressure
      repair_necessary
      rim_damaged
      wheel_covers
      wheel_bolts
      warehousing
      washing
      checking
      balancing
      cosmetic_repairing
      nitrogen
      nano
      comment
      storage_place
    }

    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${FAHRZEUG}
  ${TABELLENWERT}
  ${FAHRZEUGDETAIL}
  ${AUTOHAUS_SIMPLE}
  ${CUSTOMER_SIMPLE}
`

// Rechnungen (invoices)
export const INVOICE = gql`
  fragment Invoice on Invoice {
    id
    identifier
    internal_id
    internal_fibu_number

    customer {
      ...CustomerSimple
    }
    invoiceable {
      ... on Fahrzeugangebotcontract {
        id
        internal_id
        fahrzeugangebot {
          id
          fahrzeug {
            id
            interne_nummer
            fahrzeugmarke {
              id
              marke
            }
            fahrzeugmodell {
              id
              modell
            }
          }
        }
      }
      ... on Storage {
        id
        identifier
        internal_identifier
        customer {
          ...CustomerSimple
        }
        fahrzeug {
          id
          interne_nummer
          fahrzeugmarke {
            id
            marke
          }
          fahrzeugmodell {
            id
            modell
          }
        }
        fahrzeugmarke {
          id
          marke
        }
        fahrzeugmodell {
          id
          modell
        }
      }
      __typename # just to be sure, we will load this here to
    }

    file {
      ...File
      url_show
      url_download
      filename
    }

    invoice_date
    payment_date

    invoiceItems {
      id
      position
      type
      description
      gross
      net
      tax
      count

      created_at
      updated_at
      created_by {
        id
        name
      }
      updated_by {
        id
        name
      }
    }

    type
    last_send
    last_recipient
    last_text
    status {
      id
      code
      name
      sort
    }

    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${CUSTOMER_SIMPLE}
  ${FILE}
`

// builder
export const ASSIGNMENT_COVERAGE_HISTORY = gql`
  fragment AssignmentCoverageHistory on AssignmentCoverageHistory {
    id
    policyholder {
      id
      name

      address {
        id
        strasse
        plz
        ort
      }
    }

    name
    iban
    type {
      ...Tabellenwert
    }
    street
    street_number
    zip
    city
    number_of_objects
    book_number
    inception_date
    expected_completion
    public_service
    construction_insurance
    construction_insurance_premium
    fire_insurance
    fire_insurance_premium
    owners_liability_insurance
    owners_liability_insurance_premium

    status

    sum

    created_by {
      id
      name
    }
    created_at

    updated_by {
      id
      name
    }
    updated_at
  }
  ${TABELLENWERT}
`

export const ASSIGNMENT_COVERAGE = gql`
  fragment AssignmentCoverage on AssignmentCoverage {
    id
    history(orderBy: [{ column: CREATED_AT, order: DESC }]) {
      ...AssignmentCoverageHistory
    }
    maillogs {
      ...MaillogSimpleViewData
    }
    policyholder {
      id
      name

      address {
        id
        strasse
        plz
        ort
      }
    }
    name
    iban
    type {
      ...Tabellenwert
    }
    street
    street_number
    zip
    city
    number_of_objects
    book_number
    inception_date
    expected_completion
    public_service
    construction_insurance
    construction_insurance_premium
    fire_insurance
    fire_insurance_premium
    owners_liability_insurance
    owners_liability_insurance_premium

    status

    sum

    created_by {
      id
      name
    }
    created_at

    updated_by {
      id
      name
    }
    updated_at
  }
  ${TABELLENWERT}
  ${ASSIGNMENT_COVERAGE_HISTORY}
  ${MAILLOG_SIMPLE_VIEW_DATA}
`

export const CAR_DAMAGE = gql`
  fragment CarDamage on CarDamage {
    id
    policyholder {
      id
      name
      vudaten {
        id
        partner_externe_nummer
      }
    }
    internal_id
    location
    damage_date
    damage_time
    description
    contact_driver
    contact_opponent
    notes_vu
    vu_contractnumber
    vu_damagenumber
    cs_damagenumber
    liability
    guilty_type_haftpflicht
    guilty_type_teilkasko
    guilty_type_vollkasko
    guilty_type_sb
    police
    police_file_number
    police_department
    police_address
    alcohol
    status
    created_at
    created_by {
      id
      name
    }
    updated_by {
      id
    }
    carDamageObjects {
      id
      identification
      estimated_cost_of_repair
      km
      carType {
        id
        code
        name
      }
      carOwner {
        id
        code
        name
      }
      carDamagePersons {
        id
        name
        birthdate
        address
        email
        phone
        injured
        personType {
          id
          code
          name
        }
      }
    }
    carDamagePersons {
      id
      name
      birthdate
      address
      email
      phone
      injured
      personType {
        id
        code
        name
      }
    }
    images {
      ...File
      url
      url_show
      url_download
    }
    files {
      ...File
      url
      url_show
      url_download
    }
    filesFromNotes {
      ...File
      url
      url_show
      url_download
    }
    filesFromHistoryMails {
      ...File
      url
      url_show
      url_download
    }

    maillogs {
      ...MaillogSimpleViewData
    }
    auditsWithRelationships {
      ...Audit
    }
  }
  ${FILE}
  ${MAILLOG_SIMPLE_VIEW_DATA}
  ${AUDIT}
`

export const NOTIFICATION = gql`
  fragment Notification on Notification {
    id
    type
    data

    read_at
    became_superfluous_at
    created_at
  }
`

export const NOTIFICATION_COUNTS = gql`
  fragment NotificationCounts on User {
    id

    notificationsCount
    unreadNotificationsCount: notificationsCount(unread: true)

    # both
    unreadIssueMailReceived: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Supportcenter\\\\IssueMailReceived"]
    )

    # carsharer
    unreadClaimstatementFeedbackReceived: notificationsCount(
      unread: true
      type: [
        "App\\\\Notifications\\\\Schadenmeldung\\\\ClaimstatementFeedbackReceived"
      ]
    )

    # other
    unreadNoteCreated: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\NoteCreated"]
    )
    unreadCreateLead: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Leads\\\\CreateLead"]
    )
    unreadCreateLeadReminder: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Leads\\\\CreateLeadReminder"]
    )
    unreadExpiredFahrzeugreservation: notificationsCount(
      unread: true
      type: [
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\ExpiredFahrzeugreservation"
      ]
    )
    unreadFahrzeugeventCreated: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Fahrzeugmarkt\\\\FahrzeugeventCreated"]
    )
    unreadStorageeventCreated: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Fahrzeugmarkt\\\\StorageeventCreated"]
    )
    unreadMeeting: notificationsCount(
      unread: true
      type: [
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\MeetingCreated"
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\MeetingDeleted"
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\MeetingReminder"
      ]
    )
    unreadReceivedMail: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Fahrzeugmarkt\\\\ReceivedMail"]
    )
    unreadSelfdisclosureFeedbackReceived: notificationsCount(
      unread: true
      type: [
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\SelfdisclosureFeedbackReceived"
      ]
    )
    unreadDataprotectionSigned: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Fahrzeugmarkt\\\\DataprotectionSigned"]
    )
    unreadRentalcontractSigned: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Fahrzeugmarkt\\\\RentalcontractSigned"]
    )
    unreadLeadfeedbackFeedbackReceived: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Leads\\\\LeadfeedbackFeedbackReceived"]
    )
    unreadSetBearbeiterLead: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Leads\\\\SetBearbeiterLead"]
    )
    unreadCheckProfits: notificationsCount(
      unread: true
      type: [
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\CheckProfits"
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\CheckUpdatedProfits"
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\CarRevenueWarning"
      ]
    )
    unreadOffer: notificationsCount(
      unread: true
      type: [
        "App\\\\Notifications\\\\Fahrzeugmarkt\\\\ChangeFahrzeugangebotprice"
      ]
    )
    unreadCustomerReceivedMail: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\Customers\\\\CustomerReceivedMail"]
    )
    unreadFormrequestFeedbackReceived: notificationsCount(
      unread: true
      type: ["App\\\\Notifications\\\\FormrequestFeedbackReceived"]
    )
  }
`

export const FRESHBIKE_BIKE = gql`
  fragment FreshbikeBike on FreshbikeBike {
    id
    internal_id
    name
    brand
    style
    type
    identification
    purchase_price
    purchase_date
    insurance_end_date
    premium
    invoice {
      ...File
      url_show
    }
    claims {
      id
      internal_id
      location
      date
      time
      guilty_type
      status
      created_at
      created_by {
        id
        name
      }
      updated_at
      updated_by {
        id
        name
      }
    }
    status

    created_at
    updated_at

    created_by {
      id
      name
    }
  }
  ${FILE}
`

export const FRESHBIKE_APPLICATION = gql`
  fragment FreshbikeApplication on FreshbikeApplication {
    id
    internal_id
    auditsWithRelationships {
      ...Audit
    }
    maillogs {
      ...MaillogSimpleViewData
    }
    policyholder {
      id
      name
    }
    bikes {
      ...FreshbikeBike
    }
    canceledBikes {
      ...FreshbikeBike
    }

    name
    note_for_insurer
    inception_date
    premium
    iban
    status

    created_at
    updated_at
  }
  ${AUDIT}
  ${MAILLOG_SIMPLE_VIEW_DATA}
  ${FRESHBIKE_BIKE}
`

export const FRESHBIKE_CLAIM = gql`
  fragment FreshbikeClaim on FreshbikeClaim {
    id
    internal_id

    bike {
      id
      name
      internal_id
      application {
        id
        policyholder {
          id
          name
        }
      }
    }

    files {
      ...File
      url_show
      url_download
    }

    location
    date
    time
    guilty_type
    description
    note_for_insurer
    vu_contractnumber
    vu_claimnumber
    cs_claimnumber
    police
    police_file_number
    police_department
    police_address

    status

    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${FILE}
`

export const FRESHCARSHARING_CAR = gql`
  fragment FreshcarsharingCar on FreshcarsharingCar {
    id
    license_number
    evb
    vin
    wkz
    insurance_date
    calculation_date
    premium
    policyholder {
      id
      name
      freshcarsharingCurrentContractData {
        id
        praemien_type
      }
    }
    carGroup {
      id
      group
      type
    }
    certificate {
      ...File
      url_show
    }
    greencard {
      ...File
      url_show
    }
    status_green_card

    audits {
      ...Audit
    }
    maillogs {
      ...MaillogSimpleViewData
    }
    status
  }
  ${FILE}
  ${MAILLOG_SIMPLE_VIEW_DATA}
  ${AUDIT}
`

export const FRESHCARSHARING_APPLICATION = gql`
  fragment FreshcarsharingApplication on FreshcarsharingApplication {
    id
    auditsWithRelationships {
      ...Audit
    }
    maillogs {
      ...MaillogSimpleViewData
    }
    policyholder {
      id
      name
    }
    cars {
      ...FreshcarsharingCar
    }

    name
    iban
    status

    premium

    created_at
    updated_at
  }
  ${FRESHCARSHARING_CAR}
  ${MAILLOG_SIMPLE_VIEW_DATA}
  ${AUDIT}
`

export const FEEDBACK = gql`
  fragment Feedback on Feedback {
    id
    token
    recipient
    last_reminder_at
    message
    status

    submitted_at
    created_at
  }
`

export const SELFDISCLOSURE = gql`
  fragment Selfdisclosure on Selfdisclosure {
    id

    feedback {
      ...Feedback
    }

    selfdiscable {
      ... on Lead {
        id
      }
      ... on Customer {
        id
      }
      __typename # just to be sure, we will load this here to
    }

    # company details if exists
    company_name
    company_type
    company_sector
    company_vat_number
    company_foundingdate
    company_employees
    company_street
    company_zip
    company_city
    company_phone
    company_email
    company_bank
    company_iban
    company_tax_id_number

    # Other
    note

    # Techical
    type

    contactpersons {
      type

      # Person: Details
      salutation {
        id
        code
        name
      }
      title
      firstname
      lastname
      birthname
      birthdate
      birthplace
      nationality
      maritalStatus {
        id
        code
        name
      }
      children

      # Person: Licence
      id_number
      id_type
      id_issue_place
      id_issue_date

      # Person: Tax
      tax_id_number

      # Person: Address
      residential_type
      rent
      street
      zip
      city
      residing_since
      previous_street
      previous_zip
      previous_city

      # Person: Communication
      phone
      mobile
      fax
      email

      # Person: Bank
      bank
      iban

      # Employer / Company
      company_name
      company_sector
      company_position
      company_street
      company_zip
      company_city
      company_phone
      company_email
      employment_description
      employment_group
      employed_since
      employment_type
      income_employment
      income_other
      income_other_description
    }

    # files
    files {
      ...File
      url_show
      url_download
    }
  }
  ${FILE}
  ${FEEDBACK}
`

export const CLAIMSTATEMENT = gql`
  fragment Claimstatement on Claimstatement {
    id

    feedback {
      ...Feedback
    }

    firstname
    lastname
    birthdate
    phone
    email
    street
    zip
    city
    injured
    liability
    alcohol
    license_number
    message
  }
  ${FEEDBACK}
`

export const LEADFEEDBACK = gql`
  fragment Leadfeedback on Leadfeedback {
    id

    feedback {
      ...Feedback
    }

    positive
    negative
    rating_responsetime
    rating_kindness
    rating_quality
  }
  ${FEEDBACK}
`

export const FORMREQUEST = gql`
  fragment Formrequest on Formrequest {
    id
    name

    feedback {
      ...Feedback
    }

    response
    files {
      ...File
      url_show
      url_download
    }
  }
  ${FEEDBACK}
  ${FILE}
`

export const COMMISSION = gql`
  fragment Commission on Commission {
    id

    status
    amount
    date

    created_at
  }
`
export const RENTALCONTRACT = gql`
  fragment Rentalcontract on Rentalcontract {
    id
    internal_id
    type
    fahrzeug {
      id
      interne_nummer
    }
    customer {
      ...CustomerSimple
    }
    lead {
      id
      interne_nummer
      shortDescription
      vorname
      nachname
      zusatz
      adresse
      plz
      ort
      telefon
      email
    }
    file {
      ...File

      filename
    }
    vehicle_type
    vehicle_license_plate
    vehicle_vin
    vehicle_tank_level {
      ...Tabellenwert
    }
    vehicle_tank_level_arrival {
      ...Tabellenwert
    }
    vehicle_takeover
    vehicle_return
    vehicle_return_actual
    vehicle_mileage_departure
    vehicle_mileage_arrival
    vehicle_agreement

    deposit
    rental_fee
    km_inclusive
    km_costs
    deductible
    deductible_partial

    damages
    damage_type
    damage_costs
    damages_description

    comment
    comment_arrival
    recipient
    digital_signature

    deposit_refund
    total_costs_rental
    total_costs_deposit
    total_costs_km
    total_costs_cleaning
    total_costs_deductible
    total_costs_deductible_partial

    status

    created_at
    updated_at
    created_by {
      id
      name
    }
    updated_by {
      id
      name
    }
  }
  ${CUSTOMER_SIMPLE}
  ${TABELLENWERT}
  ${FILE}
`
