// We have to import the font-awesome icons in the file vue-fontawesome.js first
import { VuetifyIcons as FreshVueIcons } from '@freshsolutions/fresh-vue'
import { VuetifyIcons as FreshVueAuditsIcons } from '@freshsolutions/fresh-vue-audits'
import { VuetifyIcons as FreshVueCardsIcons } from '@freshsolutions/fresh-vue-cards'
import { VuetifyIcons as FreshVueFilesIcons } from '@freshsolutions/fresh-vue-files'
import { VuetifyIcons as FreshVueNotesIcons } from '@freshsolutions/fresh-vue-notes'
import { VuetifyIcons as FreshVueFormsIcons } from '@freshsolutions/fresh-vue-forms'
import { VuetifyIcons as FreshVueHistoryIcons } from '@freshsolutions/fresh-vue-history'

import actions from './actions.js'
import theme from './theme.js'
import maillogs from './maillogs.js'
import history from './history.js'
import editor from './editor.js'
import emailings from './emailings.js'
import imports from './imports.js'
import notifications from './notifications.js'
import todos from './todos.js'
import forms from './forms.js'
import supportcenter from './supportcenter.js'
import commission from './commission.js'
import carsharing from './carsharing.js'

import freshcarsharing from './freshcarsharing.js'
import freshbike from './freshbike.js'
import freshbauimmo from './freshbauimmo.js'
import freshcarflat from './freshcarflat.js'
import tradeInProcess from './tradeInProcess.js'
import workshoprepairreport from './workshoprepairreport.js'
import bgRemovalProcess from './bgRemovalProcess.js'
import signatureRequest from './signatureRequest.js'
import dataprotection from './dataprotection.js'
import rentalcontract from './rentalcontract.js'
import carsharingadjustment from './carsharingadjustment.js'

import administration from './administration.js'
import misc from './misc.js'

export default {
  ...FreshVueIcons,
  ...FreshVueAuditsIcons,
  ...FreshVueCardsIcons,
  ...FreshVueFilesIcons,
  ...FreshVueNotesIcons,
  ...FreshVueFormsIcons,
  ...FreshVueHistoryIcons,
  ...actions,
  ...theme,
  ...maillogs,
  ...history,
  ...editor,
  ...emailings,
  ...imports,
  ...notifications,
  ...todos,
  ...forms,
  ...supportcenter,
  ...commission,
  ...carsharing,

  ...freshcarsharing,
  ...freshbike,
  ...freshbauimmo,
  ...freshcarflat,
  ...tradeInProcess,
  ...workshoprepairreport,
  ...bgRemovalProcess,
  ...signatureRequest,
  ...dataprotection,
  ...rentalcontract,
  ...carsharingadjustment,

  ...administration,
  ...misc
}
