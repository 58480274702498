import { IconFontawesome, IconCalendar } from '@freshsolutions/fresh-vue'

export default {
  'action-save': {
    component: IconFontawesome,
    props: {
      icon: 'floppy-disk'
    }
  },
  'action-save-draft': {
    component: IconFontawesome,
    props: {
      icon: 'floppy-disk'
    }
  },
  'action-send': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'action-send-email': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-send-expose': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-send-contract': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'action-send-order': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'clipboard',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'action-schedule-meeting': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'calendar-day',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-start-phonecall': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'phone',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'arrow-right',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-start-phonecall-phone': {
    component: IconFontawesome,
    props: {
      icon: 'phone'
    }
  },
  'action-start-phonecall-whatsapp': {
    component: IconFontawesome,
    props: {
      icon: 'fa-brands fa-whatsapp'
    }
  },
  'action-start-selfdisclosure': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'id-card',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-start-leadfeedback': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'star',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-start-claimstatement': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'comment',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-start-formrequest': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pen',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-start-job': {
    component: IconFontawesome,
    props: {
      icon: 'play-circle'
    }
  },
  'action-search': {
    component: IconFontawesome,
    props: {
      icon: 'magnifying-glass'
    }
  },
  'action-search-advanced': {
    component: IconFontawesome,
    props: {
      icon: 'filter'
    }
  },
  'action-filter': {
    component: IconFontawesome,
    props: {
      icon: 'filter'
    }
  },
  'action-add': {
    component: IconFontawesome,
    props: {
      icon: 'circle-plus'
    }
  },
  'action-add-fab': {
    component: IconFontawesome,
    props: {
      icon: 'plus'
    }
  },
  'action-add-GF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user-tie',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'circle-plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-add-AN': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'circle-plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-add-GVT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'circle-plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-create-note': {
    component: IconFontawesome,
    props: {
      icon: 'sticky-note'
    }
  },
  'action-create-issue': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'action-create-precontractual': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'info',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-create-contract': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-create-purchase': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-create-carsharing-sondervertrag': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  'action-reminder': {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },
  'action-reminder-set': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'bell',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'action-edit': {
    component: IconFontawesome,
    props: {
      icon: 'pen'
    }
  },
  'action-reserve': {
    component: IconFontawesome,
    props: {
      icon: 'tag'
    }
  },
  'action-copy': {
    component: IconFontawesome,
    props: {
      icon: 'copy'
    }
  },
  'action-delete': {
    component: IconFontawesome,
    props: {
      icon: 'trash',
      color: 'error'
    }
  },
  'action-delete_uncolored': {
    component: IconFontawesome,
    props: {
      icon: 'trash'
    }
  },
  'action-restore': {
    component: IconFontawesome,
    props: {
      icon: 'trash-arrow-up'
    }
  },
  'action-cancel': {
    component: IconFontawesome,
    props: {
      icon: 'xmark',
      color: 'error'
    }
  },
  'action-clear': {
    component: IconFontawesome,
    props: {
      icon: 'circle-xmark'
    }
  },
  'action-change-status-default': {
    component: IconFontawesome,
    props: {
      icon: 'toggle-off'
    }
  },
  'action-change-status-closed': {
    component: IconFontawesome,
    props: {
      icon: 'toggle-on'
    }
  },
  'action-show': {
    component: IconFontawesome,
    props: {
      icon: 'eye'
    }
  },
  'action-car-return': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'person-walking-arrow-loop-left',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-show-pdf': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-show-email-body': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-show-print': {
    component: IconFontawesome,
    props: {
      icon: 'print'
    }
  },
  'action-show-email-headers': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'newspaper',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-show-email-attachments': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'paperclip',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-show-attachments': {
    component: IconFontawesome,
    props: {
      icon: 'paperclip'
    }
  },
  'action-show-data': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-show-events': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'list',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-show-emailing': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'mail-bulk',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-hide': {
    component: IconFontawesome,
    props: {
      icon: 'eye-slash'
    }
  },
  'action-download': {
    component: IconFontawesome,
    props: {
      icon: 'download'
    }
  },
  'action-import': {
    component: IconFontawesome,
    props: {
      icon: 'file-import'
    }
  },
  'action-export': {
    component: IconFontawesome,
    props: {
      icon: 'file-export'
    }
  },
  'action-export-pdf': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },
  'action-fileupload': {
    component: IconFontawesome,
    props: {
      icon: 'file-arrow-up'
    }
  },
  'action-chart': {
    component: IconFontawesome,
    props: {
      icon: 'chart-area'
    }
  },
  'action-reload': {
    component: IconFontawesome,
    props: {
      icon: 'arrows-rotate'
    }
  },
  'action-previous': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-left'
    }
  },
  'action-next': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-right'
    }
  },
  'action-today': {
    component: IconCalendar
  },
  'action-number-add': {
    component: IconFontawesome,
    props: {
      icon: 'plus'
    }
  },
  'action-number-subtract': {
    component: IconFontawesome,
    props: {
      icon: 'minus'
    }
  },
  'action-drag': {
    component: IconFontawesome,
    props: {
      icon: 'grip-lines'
    }
  },
  'action-logout': {
    component: IconFontawesome,
    props: {
      icon: 'right-from-bracket'
    }
  },
  'action-expand': {
    component: IconFontawesome,
    props: {
      icon: 'chevron-down'
    }
  },
  'action-link': {
    component: IconFontawesome,
    props: {
      icon: 'link'
    }
  },
  'action-unlink': {
    component: IconFontawesome,
    props: {
      icon: 'link-slash'
    }
  },
  'action-disable': {
    component: IconFontawesome,
    props: {
      icon: 'ban',
      color: 'error'
    }
  },
  'action-enable': {
    component: IconFontawesome,
    props: {
      icon: 'trash-arrow-up',
      color: 'warning'
    }
  },
  'action-disable-freshcarflat': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'ban',
          color: 'error',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-enable-freshcarflat': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash-arrow-up',
          color: 'warning',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-disable-fahrzeugmarkt': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'store',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'ban',
          color: 'error',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-enable-fahrzeugmarkt': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'store',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash-arrow-up',
          color: 'warning',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-assign': {
    component: IconFontawesome,
    props: {
      icon: 'link'
    }
  },
  'action-assign-to-me': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'link',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'user',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-assign-inserat': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrow-right',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-forward': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'action-maillogs': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'action-history': {
    component: IconFontawesome,
    props: {
      icon: 'clock-rotate-left'
    }
  },
  'action-permissions': {
    component: IconFontawesome,
    props: {
      icon: 'crown'
    }
  },
  'action-userverify': {
    component: IconFontawesome,
    props: {
      icon: 'user-check'
    }
  },
  'action-ok': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },
  'action-info': {
    component: IconFontawesome,
    props: {
      icon: 'circle-info'
    }
  },
  'action-warning': {
    component: IconFontawesome,
    props: {
      icon: 'triangle-exclamation'
    }
  },
  'action-settings': {
    component: IconFontawesome,
    props: {
      icon: 'wrench'
    }
  },
  'action-preview': {
    component: IconFontawesome,
    props: {
      icon: 'eye'
    }
  },
  'action-help': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },
  'action-feedback': {
    component: IconFontawesome,
    props: {
      icon: 'star'
    }
  },
  'action-create-customer': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-customer-info': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'info',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-assign-partnertags': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'tags',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-6 down-6 right-8'
        }
      ]
    }
  },
  'action-manage-partnertags': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'tags',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'gear',
          transform: 'shrink-6 down-6 right-8'
        }
      ]
    }
  },
  'action-assign-groups': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'layer-group',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-6 down-6 right-8'
        }
      ]
    }
  },
  'action-manage-groups': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'layer-group',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'gear',
          transform: 'shrink-6 down-6 right-8'
        }
      ]
    }
  },
  'action-goto': {
    component: IconFontawesome,
    props: {
      icon: 'external-link-square-alt'
    }
  },
  'action-apply': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },
  'action-pin': {
    component: IconFontawesome,
    props: {
      icon: 'thumbtack'
    }
  },
  'action-reset': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-rotate-left'
    }
  },
  'action-create-event': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  'action-create-claim': {
    component: IconFontawesome,
    props: {
      icon: 'car-burst'
    }
  },
  'action-mark-as-read': {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open'
    }
  },
  'action-mark-as-read-all': {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open'
    }
  },
  'action-mark-as-unread': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'action-group-by': {
    component: IconFontawesome,
    props: {
      icon: 'layer-group'
    }
  },

  // Actions - Stepper
  'action-previous-step': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-left'
    }
  },
  'action-next-step': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-right'
    }
  },
  'action-antrag-absenden': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },

  // Actions - Dialogs
  'action-fullscreen-expand': {
    component: IconFontawesome,
    props: {
      icon: 'expand'
    }
  },
  'action-fullscreen-compress': {
    component: IconFontawesome,
    props: {
      icon: 'compress'
    }
  },
  'action-close': {
    component: IconFontawesome,
    props: {
      icon: 'xmark'
    }
  },
  'action-add-vunumber': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'shield-blank',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-close-damage': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'circle-xmark',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  // Fahrzeugmarkt
  'action-start-vehicle-valuation': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },

  'action-edit-background': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'slash',
      iconMask: 'image',
      iconChildren: [
        {
          icon: 'slash',
          transform: 'up-1 left-1'
        }
      ]
    }
  }
}
