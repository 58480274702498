import Vue from 'vue'
import freshVueHistory from '@freshsolutions/fresh-vue-history'
import { apolloClient } from '@/plugins/vue-apollo'
import i18n from '@/plugins/vue-i18n'

const eventTextFormatter = function (item) {
  const typenameText = Vue.filter('historyTypename')(item.historieable_type)
  const eventText = Vue.filter('historyEventAll')(item.event)

  switch (item.event) {
    case 'CREATED':
    case 'UPDATED':
    case 'DELETED':
    case 'RESTORED':
      return typenameText + ' ' + eventText
    default:
      return eventText
  }
}

Vue.use(freshVueHistory, {
  i18n: i18n,
  apollo: apolloClient,
  eventTextFormatter: eventTextFormatter
})
