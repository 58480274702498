import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  administration: {
    component: IconFontawesome,
    props: {
      icon: 'gear'
    }
  },

  // account
  'administration-account-data': {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  'administration-account-emails': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'administration-account-external-applications': {
    component: IconFontawesome,
    props: {
      icon: 'globe'
    }
  },
  'administration-account-intermediary': {
    component: IconFontawesome,
    props: {
      icon: 'user-tie'
    }
  },
  'administration-account-security': {
    component: IconFontawesome,
    props: {
      icon: 'lock'
    }
  },
  'administration-account-privacy': {
    component: IconFontawesome,
    props: {
      icon: 'shield'
    }
  },

  // access
  'administration-access-users': {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },

  // dealership
  'administration-dealership-informations': {
    component: IconFontawesome,
    props: {
      icon: 'circle-info'
    }
  },
  'administration-dealership-openinghours': {
    component: IconFontawesome,
    props: {
      icon: 'clock'
    }
  },
  'administration-dealership-contacts': {
    component: IconFontawesome,
    props: {
      icon: 'address-card'
    }
  },
  'administration-dealership-marketplaces': {
    component: IconFontawesome,
    props: {
      icon: 'store'
    }
  },
  'administration-dealership-external-applications': {
    component: IconFontawesome,
    props: {
      icon: 'globe'
    }
  },
  'administration-dealership-imports': {
    component: IconFontawesome,
    props: {
      icon: 'file-import'
    }
  },
  'administration-dealership-phone': {
    component: IconFontawesome,
    props: {
      icon: 'phone'
    }
  },

  // customers
  'administration-customers-email': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'administration-customers-forms': {
    component: IconFontawesome,
    props: {
      icon: 'file-pen'
    }
  },

  // leads
  'administration-leads-feedback': {
    component: IconFontawesome,
    props: {
      icon: 'star'
    }
  },
  'administration-leads-assignment': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-right-from-bracket'
    }
  },
  'administration-leads-escalation': {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },

  // vehicles
  'administration-vehicles-dealer': {
    component: IconFontawesome,
    props: {
      icon: 'shop'
    }
  },
  'administration-vehicles-holdingdays': {
    component: IconFontawesome,
    props: {
      icon: 'clock'
    }
  },
  'administration-vehicles-profits': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'administration-vehicles-reminders': {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },
  'administration-vehicles-backgrounds': {
    component: IconFontawesome,
    props: {
      icon: 'image'
    }
  },
  'administration-vehicles-rentalcontracts': {
    component: IconFontawesome,
    props: {
      icon: 'file-contract'
    }
  },

  // documents
  'administration-documents-expose': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },
  'administration-documents-invoice': {
    component: IconFontawesome,
    props: {
      icon: 'file-invoice'
    }
  },

  // other
  'administration-other-email': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'administration-other-textblocks': {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open-text'
    }
  }
}
