import Vue from 'vue'

Vue.filter('historyEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'CREATED':
      return 'erstellt'
    case 'UPDATED':
      return 'geändert'
    case 'DELETED':
      return 'gelöscht'
    case 'RESTORED':
      return 'wiederhergestellt'
    case 'NOTE_CREATED':
      return 'Notiz erstellt'
    case 'NOTE_DELETED':
      return 'Notiz gelöscht'
    case 'MEETING_CREATED':
      return 'Termin erstellt'
    case 'MEETING_DELETED':
      return 'Termin gelöscht'

    default:
      return value
  }
})

Vue.filter('historyEventAll', function (value) {
  if (!value) return ''

  const filters = [
    Vue.filter('customerHistoryEvent'),
    Vue.filter('damageHistoryEvent'),
    Vue.filter('fahrzeugmarktFahrzeugHistoryEvent'),
    Vue.filter('fahrzeugmarktFahrzeugangebotHistoryEvent'),
    Vue.filter('freshbikeBikeHistoryEvent'),
    Vue.filter('freshbikeClaimHistoryEvent'),
    Vue.filter('historyEvent'),
    Vue.filter('issueHistoryEvent'),
    Vue.filter('leadHistoryEvent'),
    Vue.filter('lessorCertificateHistoryEvent'),
    Vue.filter('storageHistoryEvent'),
    Vue.filter('userHistoryEvent'),
    Vue.filter('workshoprepairreportHistoryEvent')
  ]

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]
    const _return = filter(value)
    if (_return && _return !== value) {
      return _return
    }
  }

  return value
})

Vue.filter('historyTypename', function (value) {
  if (!value) return ''

  switch (value) {
    // CarDamage
    case 'CarDamage':
    case 'App\\Models\\schadenmeldung\\CarDamage':
      return 'Schadenmeldung'

    // FreshcarsharingApplication
    case 'FreshcarsharingApplication':
    case 'App\\Models\\freshcarsharing\\FreshcarsharingApplication':
      return 'Antrag freshCARsharing'

    // FreshcarsharingCar
    case 'FreshcarsharingCar':
    case 'App\\Models\\freshcarsharing\\FreshcarsharingCar':
      return 'Fahrzeug freshCARsharing'

    // Sicherungsschein
    case 'FreshcarsharingLessorCertificate':
    case 'App\\Models\\freshcarsharing\\FreshcarsharingLessorCertificate':
      return 'Sicherungsschein'

    // Customer
    case 'Customer':
    case 'App\\Models\\fahrzeugmarkt\\Customer':
      return 'Kunde'

    // Fahrzeug
    case 'Fahrzeug':
    case 'App\\Models\\fahrzeugmarkt\\Fahrzeug':
      return 'Fahrzeug'

    // Fahrzeugangebot
    case 'Fahrzeugangebot':
    case 'App\\Models\\fahrzeugmarkt\\Fahrzeugangebot':
      return 'Fahrzeugangebot'

    // TradeInProcess
    case 'TradeInProcess':
    case 'App\\Models\\fahrzeugmarkt\\TradeInProcess':
      return 'Ankaufprozess'

    // Workshoprepairreport
    case 'Workshoprepairreport':
    case 'App\\Models\\fahrzeugmarkt\\Workshoprepairreport':
      return 'Technische Bewertung'

    // Lead
    case 'Lead':
    case 'App\\Models\\leads\\Lead':
      return 'Lead'

    // Storage
    case 'Storage':
    case 'App\\Models\\fahrzeugmarkt\\Storage':
      return 'Einlagerung'

    // freshBIKE
    case 'FreshbikeBike':
    case 'App\\Models\\freshbike\\FreshbikeBike':
      return 'Fahrrad'

    // freshBIKE claim
    case 'FreshbikeClaim':
    case 'App\\Models\\freshbike\\FreshbikeClaim':
      return 'Schadenmeldung'

    // Issue
    case 'Issue':
    case 'App\\Models\\Issue':
      return 'Vorgang'

    default:
      return value
  }
})
