import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'warning-target-loss-ratio': {
    component: IconFontawesome,
    props: {
      icon: 'triangle-exclamation',
      color: 'warning'
    }
  },

  'warning-simulation': {
    component: IconFontawesome,
    props: {
      icon: 'sliders',
      color: 'warning'
    }
  },

  'carsharing-list-damages': {
    component: IconFontawesome,
    props: {
      icon: 'list'
    }
  }
}
